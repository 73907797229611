import Earn from "pages/Earn/Earn";
import Entry from "pages/Entry/Entry";
import EntryDesc from "pages/Entry/EntryDesc";
import Friends from "pages/Friends/Friends";
import Home from "pages/Home/Home";
import Loading from "pages/Loading/Loading";
import Mining from "pages/Mining/Mining";
import Profile from "pages/Profile/Profile";
import { RouteObject } from "react-router-dom";
import { NavigationBarTitles } from "types/locale";
import Icon from "UI/Icon";

type Route = {
  route:RouteObject
  // titleKey?: keyof NavigationBarTitles
  titleKey?: string
  navBarIcon?:JSX.Element
}

const routes:Route[] = [
    {
      route:{
        path: "/",
        element: <Loading />,
      },
    },
    {
      route:{
        path: "/entry",
        element: <Entry />,
      },
    },
    {
      route:{
        path: "/entry-desc",
        element: <EntryDesc />,
      },
    },
    {
      route:{
        path: "/home",
        element: <Mining />,
      },
      navBarIcon:<Icon icon="navigationMining"/>,
      titleKey:'Майнинг',
    },
    {
      route:{
        path: "/earn",
        element: <Earn />,
      },
      navBarIcon:<Icon icon="navigationEarn"/>,
      titleKey:'Заработать',
    },
    {
      route:{
        path: "/services",
        element: <Home />,
      },
      navBarIcon:<Icon icon="navigationServices"/>,
      titleKey:'Услуги',
    },
    {
      route:{
        path: "/friends",
        element: <Friends />,
      },
      navBarIcon:<Icon icon="navigationFriends"/>,
      titleKey:'Друзья',
    },
    {
      route:{
        path: "/profile",
        element: <Profile/>,
      },
      navBarIcon:<Icon icon="navigationProfile"/>,
      titleKey:'Профиль',
    },
   
  ]
  export default routes