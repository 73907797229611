import Button from "UI/Button";
import Icon from "UI/Icon";
import PageWrapper from "UI/PageWrapper";
import ProfileTonConnect from "./components/ProfileTonConnect";
import { useContext, useState, useEffect } from "react";
import appContext from "utils/app-context";
import "./Profile.css";
import formatNumber from "utils/format-number";
import profileEdit from "api/profile-edit";

const Profile = () => {
  const { user } = useContext(appContext);
  const [formData, setFormData] = useState({
    about: "",
    birthday: "",
    location: "", 
    first_name:""
  });

  useEffect(() => {
    if (user) {
      setFormData({
        about: user.about || "",
        birthday: user.birthday || "",
        location: "", 
        first_name:user.first_name || ""
      });
    }
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    console.log("Сохраненные данные:", formData);
    const response = await profileEdit("", formData.birthday, formData.about, "");
    if (response?.result) {
      console.log("Профиль успешно обновлен", response.data);
    } else {
      console.log("Ошибка обновления профиля");
    }
  };

  return (
    <PageWrapper className="profile-page">
      <div className="profile-page-top">
        <Button className="profile-page-back-button btn-sl">
          <Icon icon="arrowRight" />
        </Button>
        <ProfileTonConnect />
      </div>
      <div className="profile-page-content">
        <div className="profile-page-row">
          <div className="profile-page-avatar">
            <img src={user?.avatar} alt="" />
          </div>
        </div>
        <div className="profile-page-row">
          <div className="profile-page-balance">
            <img src="img/enp.png" alt="" />
            <p className="glow">{formatNumber(user?.balance)}</p>
          </div>
          <Button className="profile-page-ton-balance">
            <Icon icon="ton" />
            <p>{12.4}</p>
            <Icon icon="withdraw" />
          </Button>
        </div>
        <div className="profile-page-row">
          <div className="profile-page-input-container">
            <label htmlFor="name">Имя</label>
            <div className="profile-page-input-wrapper gradient">
              <input type="text" name="first_name" id="first_name" value={formData.first_name} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="profile-page-row">
          <div className="profile-page-input-container">
            <label htmlFor="birthday">Дата рождения</label>
            <div className="profile-page-input-wrapper gradient">
              <input type="text" name="birthday" id="birthday" value={formData.birthday} onChange={handleChange} />
            </div>
          </div>
          <div className="profile-page-input-container">
            <label htmlFor="location">Локация</label>
            <div className="profile-page-input-wrapper gradient">
              <input type="text" name="location" id="location" value={formData.location} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="profile-page-input-container">
          <label htmlFor="about">Обо мне</label>
          <div className="profile-page-input-wrapper gradient">
            <textarea name="about" id="about" placeholder="Написать" rows={4} value={formData.about} onChange={handleChange} />
          </div>
        </div>
        <Button className="profile-page-save-btn" onClick={handleSave}>Сохранить</Button>
      </div>
    </PageWrapper>
  );
};

export default Profile;
