import React, { useState } from 'react';

type Option = {
  id: number;
  title: string;
};

type Props = {
  options: Option[];
};

const Select: React.FC<Props> = ({ options }) => {
  const [selected, setSelected] = useState<number | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value);
    setSelected(selectedId);
  };

  return (
    <div className="custom-select">
      <select value={selected ?? ''} onChange={handleChange} >
        <option value="" disabled>
          Выберите категорию
        </option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
