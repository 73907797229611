import React, { useContext, useEffect, useRef, useState } from "react";
import users from "assets/images/icons/users.svg";
import stars from "assets/images/icons/stars.svg";
import Icon from "UI/Icon";
import appContext from "utils/app-context";
import Button from "UI/Button";
import tg from "utils/tg";
import formatNumber from "utils/format-number";
import { QRCodeSVG } from "qrcode.react";
import { AnimatePresence, motion } from "framer-motion";
import html2canvas from "html2canvas";

const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
const inviteLink =
  process.env.REACT_APP_SHARE_LINK?.replace("TG_ID", String(tg_id)) ||
  process.env.REACT_APP_SHARE_LINK ||
  "";
const shareText = process.env.REACT_APP_SHARE_TEXT || "";

const FriendsReferals = () => {
  const { user } = useContext(appContext);
  const [showQr, setshowQr] = useState(false);
  const copyShareLink = (e: React.MouseEvent) => {
    tg.HapticFeedback.notificationOccurred("success");
    navigator.clipboard.writeText(inviteLink);
  };

  const QRref = useRef(null);

  const TelegramShare = () => {
    tg.openTelegramLink(
      `https://t.me/share/url?url=${inviteLink}&text=${shareText.replace(
        /\\n/g,
        "%0A"
      )}`
    );
  };

  const captureAndDownload = async () => {
    if (!QRref.current) return;

    try {
      const canvas = await html2canvas(QRref.current, {
        scale: 2,
      });
      const dataUrl = canvas.toDataURL('image/png');

      const base64Data = dataUrl.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    
      // Создаем временный URL
      const blobUrl = URL.createObjectURL(blob);
      console.log("🚀 ~ captureAndDownload ~ blobUrl:", blobUrl)

      tg.downloadFile({
        file_name:'screenshot.png',
        url:blobUrl
      });
     
    } catch (error) {
       console.log("🚀 ~ captureAndDownload ~ error:", error)
      //  Создаем ссылку для скачивания
       const canvas = await html2canvas(QRref.current, {
        scale: 2,
        });
        const dataUrl = canvas.toDataURL('image/png');
       const link = document.createElement('a');
       link.href = dataUrl;
       link.download = 'screenshot.png';
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
    }
  };

  return (
    <div className="friends-lines">
      <p style={{ fontSize: 12 }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's.
      </p>
      <div className="fr-info-blocks">
        <div className="fr-info-block glass">
          <Icon icon="info" />
          <img src={users} />
          <p>5% с оплат приглашенных друзей</p>
        </div>
        <div className="fr-info-block glass">
          <Icon icon="info" />
          <img src={stars} />
          <p>0.1 TON за приглашение организаторов</p>
        </div>
      </div>
      <p className="friends-counter">Друзья ({user?.friends}):</p>
      <ul className="friends-list">
        {user?.friends_list.map(({ coins, name, photo }) => (
          <li className="glass">
            <div>
              <img src={photo} alt="" />
              <p>{name}</p>
            </div>
            <div className="fr-reward">
              <Icon icon="ton" />
              <p className="glow">+ {formatNumber(coins)}</p>
            </div>
          </li>
        ))}
      </ul>

      <div className="invite-block glass">
        <Button onClick={copyShareLink} className="copy-link">
          <div>
            <p>{inviteLink}</p> <Icon icon="copy" />
          </div>
        </Button>
        <div className="invite-block-row">
          <Button onClick={TelegramShare} className="invite-btn">
            Пригласить друга
          </Button>
          <Button onClick={() => setshowQr(true)} className="qr-btn">
            <Icon icon="qr" />
          </Button>
        </div>
      </div>
      <AnimatePresence>
        {showQr && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="qr-code-show"
            onClick={() => setshowQr(false)}
          >
            <div  className="qr-code-show-inner" onClick={(e) => e.stopPropagation()}>
            <div className="qr-code-container" ref={QRref}>
              <QRCodeSVG id="qr-code" value={inviteLink} width={'100%'} height={'100%'}/>
            </div>
            <div className="qr-code-show-buttons">
              <Button onClick={captureAndDownload} className="qr-download">Скачать</Button>
              <Button onClick={() => setshowQr(false)} className="qr-close">Закрыть</Button>
            </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FriendsReferals;
