import React, { SVGProps } from 'react'

export type IconType = keyof typeof icons

interface Props extends SVGProps<SVGSVGElement> {
    icon:IconType
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
  return icon
}

const icons = {
    qr:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="#005075"
        strokeWidth={1.8}
        d="M2 16.9c0-1.31 0-1.964.295-2.445a2 2 0 0 1 .66-.66c.48-.295 1.136-.295 2.445-.295h1.1c1.886 0 2.828 0 3.414.586.586.586.586 1.528.586 3.414v1.1c0 1.31 0 1.964-.295 2.445a2 2 0 0 1-.66.66C9.065 22 8.409 22 7.1 22c-1.964 0-2.946 0-3.667-.442a3 3 0 0 1-.99-.99C2 19.845 2 18.863 2 16.9ZM13.5 5.4c0-1.31 0-1.964.295-2.445a2 2 0 0 1 .66-.66C14.935 2 15.591 2 16.9 2c1.964 0 2.946 0 3.668.442a3 3 0 0 1 .99.99C22 4.155 22 5.136 22 7.1c0 1.31 0 1.964-.295 2.445a2 2 0 0 1-.66.66c-.48.295-1.136.295-2.445.295h-1.1c-1.886 0-2.828 0-3.414-.586C13.5 9.328 13.5 8.386 13.5 6.5V5.4Z"
      />
      <path
        fill="#005075"
        d="M16.5 6.25c0-.515 0-.773.13-.955a.7.7 0 0 1 .165-.166C16.977 5 17.235 5 17.75 5c.515 0 .773 0 .955.13a.7.7 0 0 1 .166.165c.129.182.129.44.129.955 0 .515 0 .773-.13.955a.7.7 0 0 1-.165.166c-.182.129-.44.129-.955.129-.515 0-.773 0-.955-.13a.7.7 0 0 1-.166-.165c-.129-.182-.129-.44-.129-.955ZM12.75 22a.75.75 0 0 0 1.5 0h-1.5ZM19 12.75h-2v1.5h2v-1.5ZM12.75 19v3h1.5v-3h-1.5ZM17 12.75c-.687 0-1.258 0-1.719.046-.474.048-.913.153-1.309.418l.834 1.247c.108-.073.272-.137.627-.173.367-.037.85-.038 1.567-.038v-1.5ZM14.25 17c0-.718 0-1.2.038-1.567.036-.355.1-.519.173-.627l-1.248-.834c-.264.396-.369.835-.417 1.309-.047.461-.046 1.032-.046 1.719h1.5Zm-.278-3.787c-.3.201-.558.459-.758.76l1.247.832a1.25 1.25 0 0 1 .345-.344l-.834-1.248ZM22.75 13.5a.75.75 0 0 0-1.5 0h1.5ZM17 22.75h2v-1.5h-2v1.5ZM22.75 17v-3.5h-1.5V17h1.5ZM19 22.75c.456 0 .835 0 1.145-.02.317-.022.617-.069.907-.19l-.574-1.385c-.077.032-.194.061-.435.078-.247.017-.567.017-1.043.017v1.5ZM21.25 19c0 .476 0 .796-.017 1.043-.017.241-.046.358-.078.435l1.386.574c.12-.29.167-.59.188-.907.021-.31.021-.69.021-1.145h-1.5Zm-.198 3.54a2.75 2.75 0 0 0 1.489-1.488l-1.386-.574a1.25 1.25 0 0 1-.677.677l.574 1.386Z"
      />
      <path
        stroke="#005075"
        strokeWidth={1.8}
        d="M2 7.1c0-1.964 0-2.946.442-3.667a3 3 0 0 1 .99-.99C4.155 2 5.136 2 7.1 2c1.31 0 1.964 0 2.445.295a2 2 0 0 1 .66.66c.295.48.295 1.136.295 2.445v1.1c0 1.886 0 2.828-.586 3.414-.586.586-1.528.586-3.414.586H5.4c-1.31 0-1.964 0-2.445-.295a2 2 0 0 1-.66-.66C2 9.065 2 8.409 2 7.1Z"
      />
      <path
        fill="#005075"
        d="M5 6.25c0-.515 0-.773.13-.955a.7.7 0 0 1 .165-.166C5.477 5 5.735 5 6.25 5c.515 0 .773 0 .955.13a.7.7 0 0 1 .166.165c.129.182.129.44.129.955 0 .515 0 .773-.13.955a.7.7 0 0 1-.165.166c-.182.129-.44.129-.955.129-.515 0-.773 0-.955-.13a.7.7 0 0 1-.166-.165C5 7.023 5 6.765 5 6.25ZM5 17.75c0-.515 0-.773.13-.955a.7.7 0 0 1 .165-.166c.182-.129.44-.129.955-.129.515 0 .773 0 .955.13a.7.7 0 0 1 .166.165c.129.182.129.44.129.955 0 .515 0 .773-.13.955a.7.7 0 0 1-.165.166C7.023 19 6.765 19 6.25 19c-.515 0-.773 0-.955-.13a.7.7 0 0 1-.166-.165C5 18.523 5 18.265 5 17.75ZM16 17.75c0-.702 0-1.053.169-1.306a1 1 0 0 1 .275-.275C16.697 16 17.048 16 17.75 16c.702 0 1.053 0 1.306.169a1 1 0 0 1 .275.275c.169.253.169.604.169 1.306 0 .702 0 1.053-.169 1.306a1 1 0 0 1-.275.275c-.253.169-.604.169-1.306.169-.702 0-1.053 0-1.306-.169a1 1 0 0 1-.275-.275C16 18.803 16 18.452 16 17.75Z"
      />
    </svg>
    ),
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    ),
    navigationMining:(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.381 5.186C13.96 4.314 10.308 2.64 7.02 3.068c1.99 1.37 3.036 2.107 5.86 4.62m6.435 1.43c.872 1.423 2.544 5.074 2.117 8.362-1.37-1.989-2.106-3.036-4.62-5.859m-5.838-.203-7.05 7.05c-.572.571-.563 1.507.02 2.09.582.582 1.518.59 2.09.019l7.049-7.05m-.596-4.301 2.788 2.787c.31.31.81.31 1.119.003l3.453-3.454a.79.79 0 0 0-.002-1.118l-2.788-2.788a.79.79 0 0 0-1.118-.003l-3.454 3.454a.79.79 0 0 0 .002 1.119Z"
        />
      </svg>
    ),
    navigationEarn:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M15.5 15a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M3.5 12V6c2.105.621 6.576 1.427 12.004 1.803 2.921.202 4.382.303 5.189 1.174.807.87.807 2.273.807 5.078v2.013c0 2.889 0 4.333-.984 5.232-.983.899-2.324.768-5.005.506a61.504 61.504 0 0 1-2.011-.23"
      />
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.126 8c.377-1.423.72-4.012-.299-5.297-.645-.815-1.605-.736-2.545-.654-4.944.435-8.437 1.318-10.389 1.918C4.053 4.225 3.5 5.045 3.5 5.96"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M11.5 18h-4m0 0h-4m4 0v4m0-4v-4"
      />
    </svg>
    ),
    navigationServices:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M19.917 15.713c3.951-5.01 2.884-9.978.046-11.719-2.682-1.645-5.023-.982-6.429.074l-1.034.774m7.417 10.871c-.948 1.203-2.185 2.407-3.757 3.57C14.614 20.428 13.841 21 12.5 21c-1.341 0-2.114-.572-3.66-1.717C.722 13.275 1.518 6.153 5.037 3.994c2.682-1.645 5.023-.982 6.429.074l1.034.774m7.417 10.871-5.525-6.268a.7.7 0 0 0-.895-.13l-2.186 1.366a1.965 1.965 0 0 1-2.233-3.23l3.422-2.61"
        />
      </svg>
    ),
    navigationFriends:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <g stroke="#fff" strokeWidth={1.5} clipPath="url(#a)">
        <path
          strokeLinecap="round"
          d="M21.274 18c.75 0 1.345-.471 1.88-1.13 1.096-1.35-.703-2.43-1.389-2.957-.697-.537-1.476-.842-2.265-.913m-1-2a2.5 2.5 0 0 0 0-5M3.726 18c-.75 0-1.345-.471-1.88-1.13-1.096-1.35.703-2.43 1.389-2.957.697-.537 1.476-.842 2.265-.913m.5-2a2.5 2.5 0 0 1 0-5"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.584 15.111c-1.022.632-3.701 1.922-2.07 3.536C7.313 19.436 8.2 20 9.316 20h6.37c1.115 0 2.003-.564 2.8-1.353 1.632-1.614-1.047-2.904-2.069-3.536a7.457 7.457 0 0 0-7.832 0Z"
        />
        <path d="M16 7.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
    ),
    navigationProfile:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.283 17.111c-1.1.632-3.986 1.922-2.229 3.536C6.913 21.436 7.87 22 9.071 22h6.858c1.202 0 2.158-.564 3.017-1.353 1.757-1.614-1.128-2.904-2.229-3.536-2.58-1.481-5.854-1.481-8.434 0Z"
      />
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M16 10a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M3.354 16a9.9 9.9 0 0 1-.854-4.035C2.5 6.46 6.977 2 12.5 2s10 4.461 10 9.965A9.9 9.9 0 0 1 21.646 16"
      />
    </svg>
    ),
    ton:(
      <svg xmlns="http://www.w3.org/2000/svg" id='ton-icon' width={25} height={24} fill="none">
        <path
          fill="#fff"
          d="m22.29 6.688-8.717 13.883a1.176 1.176 0 0 1-1.994-.006L3.032 6.682a2.46 2.46 0 0 1-.365-1.294 2.547 2.547 0 0 1 2.582-2.506h14.848c1.417 0 2.57 1.118 2.57 2.5 0 .46-.13.912-.377 1.306M5.13 6.224l6.36 9.805V5.235H5.795c-.658 0-.953.436-.664.989m8.712 9.805 6.36-9.805c.293-.553-.007-.989-.665-.989h-5.695v10.794Z"
        />
      </svg>
    ),
    messages:(
      <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.5 11.667c0 5.283-4.478 9.566-10 9.566-.65.001-1.297-.059-1.935-.178-.459-.087-.688-.13-.848-.105-.16.024-.388.145-.842.386a6.5 6.5 0 0 1-4.224.657 5.292 5.292 0 0 0 1.087-2.348c.1-.53-.148-1.045-.52-1.422C3.534 16.512 2.5 14.205 2.5 11.667c0-5.283 4.478-9.567 10-9.567s10 4.284 10 9.567Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.495 12.1h.01m3.986 0h.009m-8 0h.009"
    />
  </svg>
    ),
    settings:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M15.5 12.31a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M21.011 14.407c.522-.14.783-.21.886-.345.103-.135.103-.351.103-.784v-1.934c0-.433 0-.65-.103-.784s-.364-.205-.886-.346c-1.95-.526-3.171-2.565-2.668-4.502.139-.533.208-.8.142-.956-.066-.156-.256-.264-.635-.48l-1.725-.978c-.372-.212-.558-.317-.725-.295-.167.023-.356.21-.733.587-1.459 1.455-3.873 1.455-5.333 0-.377-.377-.565-.565-.732-.587-.167-.023-.353.083-.725.294l-1.725.98c-.38.215-.57.323-.635.479-.066.156.003.423.141.956.503 1.937-.718 3.976-2.669 4.502-.522.141-.783.212-.886.346-.103.135-.103.351-.103.784v1.934c0 .433 0 .65.103.784s.364.205.886.345c1.95.526 3.171 2.566 2.668 4.503-.139.533-.208.8-.142.956.066.156.256.264.635.479l1.725.98c.372.21.558.316.725.294.167-.023.356-.21.733-.587 1.46-1.457 3.876-1.457 5.336 0 .377.376.565.564.732.587.167.022.353-.084.726-.295l1.724-.98c.38-.214.57-.322.635-.478.066-.157-.003-.423-.141-.956-.504-1.938.716-3.976 2.666-4.503Z"
    />
  </svg>
    ),
    heart:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#fff"
      d="M19.463 3.994c-2.682-1.645-5.023-.982-6.429.074-.576.433-.864.65-1.034.65-.17 0-.458-.217-1.034-.65-1.406-1.056-3.747-1.719-6.429-.074-3.519 2.159-4.315 9.28 3.803 15.29C9.886 20.427 10.659 21 12 21c1.341 0 2.114-.572 3.66-1.717 8.118-6.008 7.322-13.13 3.803-15.289Z"
    />
  </svg>
    ),
    tuners:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7 21v-3M17 21v-6M17 6V3M7 9V3"
      />
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M7 18c-.932 0-1.398 0-1.765-.152a2 2 0 0 1-1.083-1.083C4 16.398 4 15.932 4 15c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C5.602 12 6.068 12 7 12c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C10 13.602 10 14.068 10 15c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C8.398 18 7.932 18 7 18ZM17 12c-.932 0-1.398 0-1.765-.152a2 2 0 0 1-1.083-1.083C14 10.398 14 9.932 14 9c0-.932 0-1.398.152-1.765a2 2 0 0 1 1.083-1.083C15.602 6 16.068 6 17 6c.932 0 1.398 0 1.765.152a2 2 0 0 1 1.083 1.083C20 7.602 20 8.068 20 9c0 .932 0 1.398-.152 1.765a2 2 0 0 1-1.083 1.083C18.398 12 17.932 12 17 12Z"
      />
    </svg>
    ),
    info:(
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
  >
    <g stroke="#fff" clipPath="url(#a)">
      <path
        strokeWidth={1.5}
        d="M18.333 10a8.333 8.333 0 1 0-16.667 0 8.333 8.333 0 0 0 16.667 0Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.202 14.167V10c0-.393 0-.59-.122-.711-.122-.122-.319-.122-.711-.122"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9.993 6.667h.008"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
    ),
    taskDone:(
      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <g
      stroke="#4BB8EA"
      strokeLinecap="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M14.167 2.782a8.333 8.333 0 1 0 4 5.552" />
      <path
        strokeLinejoin="round"
        d="M6.667 10.417s1.25 0 2.916 2.916c0 0 4.633-7.639 8.75-9.166"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
    ),
    arrowRight:(
      <svg className='arrow-icon' xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox='0 0 20 20' fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m7.5 15 5-5-5-5"
      />
    </svg>
    ),
    lock:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#005075"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.543 10.5 22 11c-.503-5.053-4.777-9-9.975-9C6.488 2 2 6.477 2 12s4.488 10 10.025 10c4.11 0 7.643-2.468 9.19-6"
    />
    <path
      stroke="#005075"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M10.337 10.88c-1.08 0-1.62.78-1.74 1.26-.12.48-.12 2.22-.048 2.94.24.9.84 1.272 1.428 1.392.54.048 2.82.03 3.48.03.96.018 1.68-.342 1.98-1.422.06-.36.12-2.34-.03-2.94-.318-.96-1.11-1.26-1.71-1.26h-3.36ZM10.25 10.459c0-.06.008-.406.01-.84 0-.398-.034-.78.156-1.13.71-1.414 2.75-1.27 3.254.17.087.237.093.612.09.96-.003.443.006.84.006.84"
    />
  </svg>
    ),
    gradientStar:(
      <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
      <path
        fill="url(#a)"
        d="m8.008 2.01 1.026 2.07c.14.287.514.564.829.617l1.86.311c1.19.2 1.47 1.07.613 1.93l-1.447 1.458c-.245.247-.379.723-.303 1.064L11 11.266c.327 1.429-.426 1.982-1.68 1.235L7.576 11.46c-.315-.189-.834-.189-1.155 0L4.677 12.5c-1.248.748-2.006.189-1.68-1.234l.415-1.806c.075-.34-.059-.817-.304-1.064L1.662 6.937c-.852-.858-.578-1.729.612-1.929l1.86-.311c.31-.053.683-.33.823-.618l1.027-2.07c.56-1.123 1.47-1.123 2.024 0Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={12.833}
          x2={1.167}
          y1={7}
          y2={7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BB8EA" />
          <stop offset={1} stopColor="#F3C8C7" />
        </linearGradient>
      </defs>
    </svg>

    ),
    copy:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 15c0-2.828 0-4.243.879-5.121C10.757 9 12.172 9 15 9h1c2.828 0 4.243 0 5.121.879C22 10.757 22 12.172 22 15v1c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22h-1c-2.828 0-4.243 0-5.121-.879C9 20.243 9 18.828 9 16v-1Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17 9c-.003-2.957-.047-4.489-.908-5.538a4 4 0 0 0-.554-.554C14.43 2 12.788 2 9.5 2c-3.287 0-4.931 0-6.038.908a4 4 0 0 0-.554.554C2 4.57 2 6.212 2 9.5c0 3.287 0 4.931.908 6.038a4 4 0 0 0 .554.554c1.05.86 2.58.905 5.538.908"
    />
  </svg>
    ),
    like:(
      <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none">
      <rect width={32} height={32} y={0.333} fill="url(#a)" rx={16} />
      <path
        fill="#fff"
        d="M23.463 8.328c-2.682-1.645-5.023-.983-6.429.073-.576.433-.864.65-1.034.65-.17 0-.458-.217-1.034-.65-1.406-1.056-3.747-1.718-6.429-.073-3.519 2.158-4.315 9.28 3.802 15.289 1.547 1.144 2.32 1.716 3.661 1.716 1.341 0 2.114-.572 3.66-1.716 8.118-6.009 7.322-13.13 3.803-15.29Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={32}
          x2={0}
          y1={16.333}
          y2={16.333}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BB8EA" />
          <stop offset={1} stopColor="#F3C8C7" />
        </linearGradient>
      </defs>
    </svg>
    ),
    withdraw:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke="#005075"
        strokeWidth={1.5}
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
      />
      <path
        stroke="#005075"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M7.034 12.007h5.975m0 0c0 .567-1.875 2.008-1.875 2.008m1.875-2.008c0-.583-1.875-1.986-1.875-1.986m4.9-1.01v6"
      />
    </svg>
    ),
    dislike:(
      <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} fill="none">
    <rect width={32} height={32} y={0.333} fill="url(#b)" rx={16} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16 20.333 1.5-2.5-2-2 3-2.5-2.5-4s.458-.499 1.034-.932c1.406-1.056 3.747-1.718 6.429-.073 3.519 2.158 4.315 9.28-3.803 15.289-1.546 1.144-2.319 1.716-3.66 1.716-1.341 0-2.114-.572-3.66-1.716-8.118-6.009-7.322-13.13-3.803-15.29 1.894-1.161 3.618-1.172 4.963-.706"
    />
    <defs>
      <linearGradient
        id="b"
        x1={16}
        x2={16}
        y1={0.333}
        y2={32.333}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#003852" />
        <stop offset={1} />
      </linearGradient>
    </defs>
  </svg>
    ),
    close:(
      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m12.5 13-5-5m0 5 5-5"
      />
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M18.333 10.5a8.333 8.333 0 1 0-16.666 0 8.333 8.333 0 0 0 16.666 0Z"
      />
    </svg>
    )

}

export default Icon