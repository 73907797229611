import tg from "utils/tg";
import UserData from "types/user-data";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;

interface Responce {
  result: boolean;
  data: Data;
}

interface Data {
  lang: null;
  first_name: string;
  birthday: null;
  about: null;
  wallet_address: null;
}

export default async (first_name:string,birthday:string,about:string,wallet_address:string):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "profile-edit";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("first_name", String(first_name));
    API_URL.searchParams.set("birthday", String(birthday));
    API_URL.searchParams.set("about", String(about));
    API_URL.searchParams.set("wallet_address", String(wallet_address));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};