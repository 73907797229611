import React from 'react'
import Button from 'UI/Button'

type Props = {}

const ProfileTonConnect = (props: Props) => {
  return (
    <Button className="profile-page-ton-button">
       <p>Подключить кошелек</p>
    </Button>
  )
}

export default ProfileTonConnect