import React, { useContext } from 'react'
import formatNumber from 'utils/format-number'
import Icon from './Icon'
import appContext from 'utils/app-context'

type Props = {}

const UserTopBar = (props: Props) => {
    const {user} = useContext(appContext)
  return (
    <div className='user-profile-top-bar glass'>
        <div>
            <img className='top-bar-avatar' src={user?.avatar} alt="" />
            <p className='top-bar-name'>{user?.first_name}</p>
        </div>
        <div>
            <img src="img/enp.png" alt="" />
            <p className='glow'>{formatNumber(user?.balance)}</p>
        </div>
        <div>
            <Icon icon='ton'/>
            <p className='glow'>{12.4}</p>
        </div>
        {/* <div style={{position:'relative'}}>
            <Icon icon='messages'/>
            <span className='messages-counter'>12</span>
        </div> */}
    </div>
  )
}

export default UserTopBar